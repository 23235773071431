import styled from '@emotion/styled'

const Section = styled.section`
  width: 940px;
  max-width: 100%;
  margin: auto;
  padding: 50px 20px;
`

export default Section
