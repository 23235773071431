import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import Logo from './logo'
import { COLORS } from './constants'

const Wrapper = styled.div`
  position: relative;
  background: ${COLORS.primaryColor};
`

const Background = styled.svg`
  position: absolute;
  top: -1px;
  left: 0;
`

const Content = styled.div`
  position: relative;
  padding-top: 100px;
  width: calc(100% - 260px);
  max-width: 100%;
  margin: auto;

  a {
    color: ${COLORS.text};
  }

  @media (max-width: 720px) {
    width: calc(100% - 40px);
  }
`

const Description = styled.div`
  padding-left: 20px;
  max-width: 400px;
`

const Grid = styled.div`
  margin: 0 20px;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  font-size: 0.85rem;
  border-top: black solid 1px;

  a {
    text-decoration: none;
    text-align: center;
    padding: 0 10px;
  }

  a:hover {
    text-decoration: underline;
  }
`

const Footer = () => (
  <Wrapper>
    <Background
      width="100%"
      viewBox="0 0 1400 374"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0" y="0" width="1400" height="364" fill="#ffffff" />
      <path
        d="M0 0C531.992 50.5979 838.74 51.973 1400 0V373.787H0V0Z"
        fill={COLORS.primaryColor}
      />
    </Background>

    <Content>
      <Logo />
      <Description>
        <p>
          Capture and share moments in a meaningful, personal way with
          Timelense: the postcard that comes to life with a little magic (and a
          little AR).
        </p>
        <p>
          Made with ♡ by{' '}
          <a href={`https://twitter.com/mathieudutour`}>Mathieu</a> and friends.
        </p>
      </Description>
      <Grid>
        <a
          href={`https://twitter.com/messages/compose?recipient_id=1173158581526573056`}
        >
          Contact Us
        </a>
        <Link to={'/privacy-policy'}>Privacy Policy</Link>
        <Link to={'/terms'}>Terms of Service</Link>
      </Grid>
    </Content>
  </Wrapper>
)

export default Footer
