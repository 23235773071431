export const COLORS = {
  lightWhite: '#FFFFFF',
  lightGray: '#F3F5F6',
  mediumGray: '#B0B8BA',
  gray: '#A0A4A6',
  primaryColor: '#00D749',
  secondaryBackground: '#F8F8F6',
  text: '#282828',
}

export const BORDER_RADIUS = '22px'
