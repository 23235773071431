import React from 'react'

import Layout from './layout'
import SEO from './seo'
import Hero from './hero'
import Section from './section'

export default (props: {
  children: React.ReactNode
  _frontmatter: { title?: string; subtitle?: string }
}) => {
  return (
    <Layout>
      <SEO />
      <Hero
        small
        title={props._frontmatter.title}
        content={props._frontmatter.subtitle}
      />
      <Section>{props.children}</Section>
    </Layout>
  )
}
