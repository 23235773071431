// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-empty-to-check-stored-senders-tsx": () => import("./../src/pages/empty-to-check-stored-senders.tsx" /* webpackChunkName: "component---src-pages-empty-to-check-stored-senders-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-receive-tsx": () => import("./../src/pages/receive.tsx" /* webpackChunkName: "component---src-pages-receive-tsx" */),
  "component---src-pages-stories-tsx": () => import("./../src/pages/stories.tsx" /* webpackChunkName: "component---src-pages-stories-tsx" */),
  "component---src-pages-help-mdx": () => import("./../src/pages/help.mdx" /* webpackChunkName: "component---src-pages-help-mdx" */),
  "component---src-pages-about-mdx": () => import("./../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-terms-mdx": () => import("./../src/pages/terms.mdx" /* webpackChunkName: "component---src-pages-terms-mdx" */)
}

