import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { LiteYouTubeEmbed } from 'react-lite-youtube-embed'

import Logo from './logo'
import AppStoreButton from './app-store-button'
import PlayStoreButton from './play-store-button'
import { COLORS } from './constants'

const Hero = styled.header<{ small?: boolean }>`
  min-height: ${props => (props.small ? 300 : 800)}px;
  position: relative;
  background: ${COLORS.primaryColor};
`

const Background = styled.svg`
  position: absolute;
  bottom: -1px;
  left: 0;
`

const LogoWrapper = styled(Link)`
  padding-top: 15px;
  text-decoration: none;
`

const NavBar = styled.nav<{ small?: boolean }>`
  display: flex;
  width: ${props => (props.small ? '900px' : 'calc(100% - 260px)')};
  max-width: calc(100% - 40px);
  margin: auto;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;

  ul {
    display: flex;
    margin: 0;
  }

  li {
    list-style: none;
    margin: 0;
  }

  li a {
    display: inline-block;
    text-decoration: none;
    padding-top: 46px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    color: ${COLORS.text};
  }

  @media (max-width: 800px) {
    width: ${props => (props.small ? '900px' : 'calc(100% - 40px)')};
  }

  @media (max-width: 580px) {
    li a {
      padding-top: 16px;
    }
  }
`

const ContentWrapper = styled.div<{ small?: boolean }>`
  width: ${props => (props.small ? '900px' : 'calc(100% - 260px)')};
  max-width: calc(100% - 40px);
  margin: auto;
  position: relative;

  @media (max-width: 800px) {
    width: ${props => (props.small ? '900px' : 'calc(100% - 40px)')};
  }
`

const Content = styled.div<{ small?: boolean; onShowStories?: any }>`
  position: relative;
  margin-top: ${props => (props.small ? 20 : 200)}px;
  max-width: 450px;
  z-index: 1;

  @media (max-width: 1025px) {
    margin-top: ${props =>
      props.small ? 20 : props.onShowStories ? 20 : 200}px;
  }

  @media (max-width: 580px) {
    margin-top: 20px;
  }
`

const NoAppWrapper = styled.div`
  margin-top: 20px;
  font-size: 60%;

  p {
    margin: 0;
    opacity: 80%;
  }

  button {
    background: transparent;
    height: 35px;
    border-radius: 4px;
    border: 1px solid white;
    cursor: pointer;
  }
`

const Header = ({
  withNav,
  title,
  content,
  small,
  onShowStories,
}: {
  withNav?: boolean
  title?: React.ReactNode
  content?: React.ReactNode
  small?: boolean
  onShowStories?: () => void
}) => {
  return (
    <Hero small={small}>
      <Background
        width="100%"
        viewBox="0 0 100 13"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0" y=".214" width="100" height="13" fill="#ffffff" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.922 12.071c24.728 0 50.364-6.5 65.078-12V0H0v9.968c10.896 1.361 22.656 2.103 34.922 2.103z"
          fill={COLORS.primaryColor}
        />
      </Background>
      <NavBar small={small}>
        <LogoWrapper to="/">
          <Logo />
        </LogoWrapper>
        <ul>
          {withNav !== false ? (
            <>
              <li>
                <Link to="/#how-it-works">How It Works</Link>
              </li>
              <li>
                <Link to="/#testimonials">Testimonials</Link>
              </li>
              <li>
                <Link to="/help">Help</Link>
              </li>
            </>
          ) : null}
        </ul>
      </NavBar>
      <ContentWrapper small={small}>
        <Content small={small} onShowStories={onShowStories}>
          <h1>{title || 'Postcards, reimagined.'}</h1>
          <p>
            {content ||
              (small
                ? ''
                : 'Capture and share moments in a meaningful, personal way with Timelense: the postcard that comes to life with a sprinkle of magic (and a touch of AR).')}
          </p>
          {small ? null : (
            <>
              <AppStoreButton />
              <PlayStoreButton />
            </>
          )}
          {onShowStories ? (
            <NoAppWrapper>
              <p>Can't use the app?</p>
              <button onClick={onShowStories}>
                Show the content without magic
              </button>
            </NoAppWrapper>
          ) : null}
        </Content>
        {small ? null : (
          <LiteYouTubeEmbed
            id="MYYrg2n-0To"
            title="Timelense video presentation"
            wrapperClass="yt-lite yt-lite-hero"
          />
        )}
      </ContentWrapper>
    </Hero>
  )
}

export default Header
